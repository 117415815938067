import React, { useState, useEffect } from 'react';
import { graphql, Link, useStaticQuery } from 'gatsby';
import Img from 'gatsby-image';
import { Helmet } from 'react-helmet';
import SEO from '../components/seo/SEO';
import Layout from '../layouts';

export default function JobDetail(props) {
    const [error, setError] = useState(null);
    const [loaded, isLoaded] = useState(false);
    const [job, setJob] = useState(null);
    //const [file, setFile] = useState(null);
    const [submit, setSubmit] = useState({
        error: false, 
        success: false
    });
    const [upload, setUpload] = useState({
        failure: false, 
        success: false, 
        working: false, 
        oversize:false
    });

    function onFormSubmit(e) {
        e.preventDefault();
        const applyUrl = 'https://api.lever.co/v0/postings/firewoodmarketing/' + job['id'] + `?key=${process.env.LEVER_API_KEY}`
        const form = e.target;
        const data = new FormData(form);
        let customQuestions = form.getElementsByClassName('custom-question');
        let customText = '';
        let comments = data.get('comments');

        for (var i=0; i<customQuestions.length; i++) {
            let label = customQuestions[i].querySelector('.text').textContent;
            let selector = customQuestions[i].querySelector('select');
            let value = selector[selector.selectedIndex].value;

            customText += label + ': ' + value + '\n\n';
            data.delete(selector.name);
        }
        
        data.set('comments', customText + '------\n\n' + comments);
        
        var request = new XMLHttpRequest();
        request.open('POST', applyUrl, true);

        request.onload = function () {
            if (request.status === 200) {
                form.style.display = 'none';
                form.reset();

                //setFile(null);
                setSubmit({
                    error: false, 
                    success: true
                });
                setUpload({
                    failure: false,
                    success: false,
                    working: false,
                    oversize: false
                });
                
            } else {
                setSubmit({
                    error: true, 
                    success: false
                });
                console.log("Error applying for position.");
            }
        };
        
        request.onerror = function () {
            setSubmit({
                error: true, 
                success: false
            });
            console.log("Error applying for position.");
        };
        
        request.send( data );
    }

    function onChange(e) {
        let resume = e.target.files[0];
        let filename = resume.name;
        let visibleUpload = e.target.parentElement;
        let filenameTarget = visibleUpload.querySelector('.filename');
        let defaultLabel = visibleUpload.querySelector('.default-label');

        if (filename) {
            filename = filename.replace('C:\\fakepath\\', '');
            filenameTarget.innerHTML = filename;
            filenameTarget.style.display = 'block';
            visibleUpload.classList.add('has-file');
            defaultLabel.style.display = 'none';
        } else {
            visibleUpload.classList.remove('has-file')
            filenameTarget.style.display = 'none';
            defaultLabel.style.display = 'block';
        }

        //setFile(resume);
        
        parseResume(resume);
    }

    function updateFields(data) {
        try {
            data = JSON.parse(JSON.stringify(data));
            setUpload({
                success: true
            });
        } catch (e) {
            setUpload({
                failure: true
            });
          return;
        }
        if (!data) {
          return;
        }
    
        if (data.positions && data.positions[0] && data.positions[0].org) {
            updateIfEmpty(document.querySelector('input[name=org]'), data.positions[0].org);
        }
    
        if (data.phones && data.phones[0] && data.phones[0].value) {
            updateIfEmpty(document.querySelector('input[name=phone]'), data.phones[0].value);
        }
    
        if (data.names && data.names[0]) {
            updateIfEmpty(document.querySelector('input[name=name]'), data.names[0]);
        }
    
        if (data.emails && data.emails[0] && data.emails[0].value) {
            updateIfEmpty(document.querySelector('input[name=email]'), data.emails[0].value);
        }
    
        if (data.links && data.links.length > 0) {
          var urls = ['LinkedIn', 'Twitter', 'Quora', 'GitHub']
          for (var i = 0; i < data.links.length; i++) {
            var link = data.links[i];
            for (var j = 0; j < urls.length; j++) {
                var fieldName = urls[j];
                if (link.domain && link.domain.toLowerCase().indexOf(fieldName.toLowerCase()) > -1) {
                    updateIfEmpty(document.querySelector('input[name="urls[' + fieldName + ']"]'), link.url);
                } else {
                    updateIfEmpty(document.querySelector('input[name="urls[Other]"]'), link.url);
              }
            }
          }
        }
      }
    
    function updateIfEmpty(field, val) {
        if (!field || field.value) {
            return;
        }
        field.value = val;
    }

    function parseResume(file) {
        const POST_PATH     = '/lever-parse/parseResume';
        const MAX_FILE_SIZE = 100*1000*1000; // 100 MB
        
        setUpload({
            failure: false,
            success: false,
            working: false,
            oversize: false
        });

        if (!file || file.size === 0) {
            // File changed to nothing
            return;
        }
          
        if (file.size > MAX_FILE_SIZE) {
            setUpload({
                oversize: true
            });
            return;
        }
        setUpload({
            working: true
        });
        
        const formData = new FormData();
        formData.append('resume', file);
        

        var req = new XMLHttpRequest();
        req.open('POST', POST_PATH, true);
        req.responseType = "json";

        req.onreadystatechange = function(e) {
            if (req.readyState === 4) {
                setUpload({
                    working: false
                });
                if (req.status === 200) {
                    updateFields(req.response);
                } else if (req.status === 400 && req.responseText === 'PayloadTooLargeError' ) {
                    setUpload({
                        oversize: true
                    });
                } else {
                    setUpload({
                        failure: true
                    });
                }
            }
        };
        
        req.send(formData);
    }

    useEffect(() => {
        if( !props.id ) {
            setError('The job ID may be incorrect or the position may no longer be available.');
            isLoaded(false);
            return;
        } 
       

        var url = 'https://api.lever.co/v0/postings/firewoodmarketing/' + props.id
        
        fetch( url )
            .then( 
                (res) => {
                    if (!res.ok) { throw res }
                    return res.json();
                }
            )
            .then(
                (result) => {
                    setJob(result); 
                    isLoaded(true);
                }
            ).catch(
                (error) => {
                    setError('The job ID may be incorrect or the position may no longer be available.');
                    isLoaded(false);
                }
            )

    }, [props]);
    
    const data  = useStaticQuery(graphql`
    query {
        wordpressPage(title: { eq: "Careers" }) {
            title
            ...SEO
        }
        wordpressAcfOptions {
            options{
                side_photo{
                    localFile{
                        childCloudinaryAsset{
                            fluid(maxWidth: 800){
                                ...CloudinaryAssetFluid
                            }
                        }
                    }
                }
            }
        }
        site {
            siteMetadata {
                title
                siteUrl
            }
        }
    }
  `);
    
    const currentPage = data.wordpressPage;
    const currentOptions = data.wordpressAcfOptions;
    const jobId = props.id;
    const site = data.site.siteMetadata;

    const currentPageTitle = (loaded) ? job.text + ' | ' + currentPage.title + ' | ' + site.title : currentPage.title + ' | ' + site.title;

    return (
        <Layout>
            <Helmet bodyAttributes={{ id: `page-jobdetail` }}>
                <title>{currentPageTitle}</title>
                <meta name="title" content={currentPageTitle} />
            </Helmet>
            <SEO node={currentPage} site={site} />
            <section className='section-info job'>
                <div className='content'>
                    <div className='content--line' />
                    <div className='content--main'>
                        <p className='eyebrow'>
                            <span>Careers</span>
                        </p>
                        {loaded  &&
                            
                            <div>
                                <h4>{job.text}</h4>
                                <span className="job-categories">{job.categories.department} | {job.categories.location}</span>
                                <p className="job-description" dangerouslySetInnerHTML={{ __html:job.description}} />
                            </div>
                        }
                        {(!loaded && error) &&
                            <div>
                                <h1>404 page not found</h1>
                                <p>{error}</p>
                                <p><Link to="/careers"><button>Return to Careers</button></Link></p>
                            </div>
                        }
                    </div>
                </div>
                <div className='image'>
                    <Img
                        fluid={currentOptions.options.side_photo.localFile.childCloudinaryAsset.fluid}
                    />
                </div>
            </section>
        {loaded &&
            [<section key="job-lists"
                className="section-body job-lists"
                style={{ backgroundColor: '#151A20', color: '#ffffff' }}
            >
                <div className="content">
                    <div className="content--main">
                        <div className="columns two-col">
                        {
                            job.lists.map( (list,i) => {
                                return(
                                    <div key={i}>
                                        <h5>{list.text}</h5>
                                        <ul dangerouslySetInnerHTML={{ __html:list.content }} />
                                    </div>
                                )
                            })
                        }
                        </div>
                    </div>
                </div>
            </section>,
        
            <section key="job-form" 
                className="section-body job-form application"
            >
                <div className="content">
                    <div className="content--main">
                    {submit.success &&
                        <div id="application-submitted">
                            <p>Thanks, your application has been submitted!</p>
                            <p><Link to="/careers"><button>Return to Careers</button></Link></p>
                        </div>
                    }
                        <form encType="multipart/form-data" method="POST" onSubmit={onFormSubmit}>
                            <div>
                                <div className="section page-centered application-form">
                                    <h5>Submit your application</h5>
                                    <ul>
                                        <li className="application-question resume">
                                            <label htmlFor="resume-upload-input">
                                                <div className="application-label">Resume/CV<span className="required">✱</span></div>
                                                <div className="application-field">
                                                    <div className="postings-btn template-btn-utility visible-resume-upload">
                                                        <svg className="icon icon-paperclip" x="0px" y="0px" width="16px" height="16" viewBox="0 0 16 16" enableBackground="new 0 0 16 16">
                                                            <path fill="#3F484B" d="M3.036,14.696c-0.614,0-1.219-0.284-1.788-0.853l-0.083-0.082c-0.586-0.578-2.305-2.956,0.008-5.391 c1.165-1.226,2.771-2.813,4.471-4.493C6.558,2.976,7.509,2.036,8.455,1.09c1.708-1.707,2.958-1.317,4.894,0.528 c2.288,2.178,2.707,4.322,1.718,5.463c-1.314,1.515-6.285,6.488-6.496,6.699c-0.278,0.279-0.729,0.279-1.008,0 c-0.278-0.278-0.278-0.729,0-1.008c0.051-0.051,5.146-5.148,6.427-6.625c0.294-0.339,0.339-1.629-1.624-3.498 c-1.13-1.076-1.465-1.989-2.902-0.551c-0.948,0.948-1.901,1.89-2.817,2.793C4.954,6.564,3.355,8.144,2.207,9.353 c-1.349,1.421-0.656,2.788-0.041,3.395l0.089,0.088c0.524,0.523,0.952,0.665,1.718-0.102c0.213-0.213,0.656-0.644,1.213-1.185 C6.729,10.05,9.6,7.26,10.18,6.534c0.184-0.23,0.452-0.787,0.196-1.011c-0.353-0.31-1.002,0.315-1.192,0.514 c-2.012,2.112-4.64,4.643-4.666,4.667c-0.283,0.273-0.734,0.265-1.007-0.02c-0.273-0.284-0.265-0.734,0.019-1.007 c0.026-0.025,2.633-2.535,4.622-4.624c1.291-1.356,2.48-1.201,3.162-0.604c0.832,0.727,0.636,2.154-0.021,2.974 c-0.586,0.734-2.847,2.945-5.113,5.146c-0.55,0.536-0.988,0.96-1.199,1.171C4.346,14.378,3.686,14.696,3.036,14.696L3.036,14.696z"></path>
                                                        </svg>
                                                        <span className="filename"></span>
                                                        <span className="default-label">ATTACH RESUME/CV</span>
                                                        <input className="application-file-input invisible-resume-upload" data-qa="input-resume" id="resume-upload-input" name="resume" tabIndex="-1" type="file" onChange={onChange} required />
                                                    </div>
                                                    <span className={ upload.failure ? "resume-upload-failure show" : "resume-upload-failure" }>
                                                        <div className="resume-upload-label">Couldn't auto-read resume.</div>
                                                    </span>
                                                    <span className={ upload.working ? "resume-upload-working show" : "resume-upload-working" }>
                                                        <div className="loading-indicator"></div>
                                                        <div className="resume-upload-label">Analyzing resume...</div>
                                                    </span>
                                                    <span className={ upload.success ? "resume-upload-success show" : "resume-upload-success" }>
                                                        <div className="loading-indicator completed"><svg className="icon icon-checkmark" width="16px" height="14px" enableBackground="new 0 0 16 14" viewBox="0 0 16 14"><path d="M5.608,14.94L0.754,9.465c-0.5-0.562-0.393-1.383,0.242-1.828C1.63,7.192,2.55,7.288,3.05,7.852 l2.461,2.777l7.401-9.033c0.476-0.58,1.392-0.711,2.045-0.286c0.653,0.424,0.796,1.238,0.32,1.82L5.608,14.94L5.608,14.94z"></path></svg></div>
                                                        <div className="resume-upload-label">Success!</div>
                                                    </span>
                                                </div>
                                            </label>
                                            
                                            <span className={ upload.oversize ? "resume-upload-oversize show" : "resume-upload-oversize" }>
                                                <p className="error-message">File exceeds the maximum upload size of <b>100MB</b>. Please try a smaller size.</p>
                                            </span>
                                        </li>
                                        
                                        <li className="application-question">
                                            <label>
                                                <div className="application-label">
                                                    Full name<span className="required">✱</span>
                                                </div>
                                                <div className="application-field">
                                                    <input type="text" name="name" required />
                                                </div>
                                            </label>
                                        </li>
                                        
                                        <li className="application-question">
                                            <label>
                                                <div className="application-label">
                                                    Email<span className="required">✱</span>
                                                </div>
                                                <div className="application-field">
                                                    <input name="email" type="email" pattern="[a-zA-Z0-9.#$%&amp;'*+\/=?^_`{|}~][a-zA-Z0-9.!#$%&amp;'*+\/=?^_`{|}~-]*@[a-zA-Z0-9](?:[a-zA-Z0-9-]{0,61}[a-zA-Z0-9])?(?:\.[a-zA-Z0-9](?:[a-zA-Z0-9-]{0,61}[a-zA-Z0-9])?)*" required />
                                                </div>
                                            </label>
                                        </li>
                                        
                                        <li className="application-question">
                                            <label>
                                                <div className="application-label">Phone <span className="required">✱</span></div>
                                                <div className="application-field">
                                                    <input type="text" name="phone" required />
                                                </div>
                                            </label>
                                        </li>
                                        
                                        <li className="application-question">
                                            <label>
                                                <div className="application-label">Current company </div>
                                                <div className="application-field">
                                                    <input type="text" name="org" />
                                                </div>
                                            </label>
                                        </li>
                                    </ul>
                                </div>
                                <div className="section page-centered application-form" data-qa="additional-cards">
                                    <ul>
                                        <li className="application-question custom-question">
                                            
                                            <div className="application-label full-width dropdown"> 
                                            <label htmlFor={`cards[${jobId}][field0]`}>
                                                <div className="text">
                                                    How did you learn about this role?<span className="required">✱</span>
                                                </div>
                                            </label>
                                            </div>
                                            <div className="application-field full-width required-field">
                                                <div className="application-dropdown">
                                                    <select id={`cards[${jobId}][field0]`} name={`cards[${jobId}][field0]`} required>
                                                        <option value="">Select...</option>
                                                        <option>Firewood Career Site</option>
                                                        <option>LinkedIn</option>
                                                        <option>Glassdoor</option>
                                                        <option>Firewood Employee</option>
                                                        <option>Other</option>
                                                    </select>
                                                </div>
                                            </div>
                                            
                                        </li>
                                        
                                        <li className="application-question custom-question">
                                            <label htmlFor={`cards[${jobId}][field2]`}>
                                                <div className="application-label full-width dropdown"> 
                                                    <div className="text">If offered a position with Firewood, would you be legally eligible to begin employment immediately, for the country in which the position is listed?</div>
                                                </div>
                                            </label>
                                            <div className="application-field full-width">
                                                <div className="application-dropdown">
                                                    <select id={`cards[${jobId}][field2]`} name={`cards[${jobId}][field2]`}>
                                                        <option value="">Select...</option>
                                                        <option>Yes</option>
                                                        <option>No</option>
                                                    </select>
                                                </div>
                                            </div>
                                        </li>
                                        
                                        <li className="application-question custom-question">
                                            <label htmlFor={`cards[${jobId}][field3]`}>
                                                <div className="application-label full-width dropdown"> 
                                                    <div className="text">Will you need visa sponsorship?<span className="required">✱</span>
                                                    </div>
                                                </div>
                                            </label>
                                            <div className="application-field full-width required-field">
                                                <div className="application-dropdown">
                                                    <select id={`cards[${jobId}][field3]`} name={`cards[${jobId}][field3]`} required>
                                                        <option value="">Select...</option>
                                                        <option>Yes</option>
                                                        <option>No</option>
                                                    </select>
                                                </div>
                                            </div>
                                        </li>
                                    </ul>
                                </div>
                            </div>
                            <div>   
                                <div className="section page-centered application-form">
                                    <h5>Links</h5>
                                    <ul>
                                        <li className="application-question">
                                            <label>
                                                <div className="application-label">
                                                    LinkedIn URL<span className="required">✱</span>
                                                </div>
                                                <div className="application-field">
                                                    <input type="text" name="urls[LinkedIn]" required />
                                                </div>
                                            </label>
                                        </li>

                                        <li className="application-question">
                                            <label>
                                                <div className="application-label">Twitter URL</div>
                                                <div className="application-field">
                                                    <input type="text" name="urls[Twitter]" />
                                                </div>
                                            </label>
                                        </li>
                                        
                                        <li className="application-question">
                                            <label>
                                                <div className="application-label">GitHub URL</div>
                                                <div className="application-field">
                                                    <input type="text" name="urls[GitHub]" />
                                                </div>
                                            </label>
                                        </li>
                                        
                                        <li className="application-question">
                                            <label>
                                                <div className="application-label">Portfolio URL</div>
                                                <div className="application-field">
                                                    <input type="text" name="urls[Portfolio]" />
                                                </div>
                                            </label>
                                        </li>
                                        
                                        <li className="application-question">
                                            <label>
                                                <div className="application-label">Other website</div>
                                                <div className="application-field">
                                                    <input type="text" name="urls[Other]" />
                                                </div>
                                            </label>
                                        </li>
                                    </ul>
                                </div>
                                
                                <div className="section page-centered application-form">
                                    <h5>Additional information</h5>
                                    <div className="application-additional">
                                        <textarea placeholder="Add a cover letter or anything else you want to share." name="comments"></textarea>
                                    </div>
                                </div>
                            </div>
                            <div className="section page-centered application-form last-section-apply">
                                <button className="postings-btn template-btn-submit cerulean" data-qa="btn-submit" href="#" type="submit">
                                    Send
                                </button>
                            </div>
                        {submit.error &&
                            <div id="applicaton-error">
                                <p>Oops, something went wrong. Please try submitting your application again.</p>
                            </div>
                        }
                            <input type="hidden" name="accountId" value="c866d92f-4e2e-403f-81ca-c0c4fa895998"/>
                            <input type="hidden" name="linkedInData" />
                            <input type="hidden" name="origin" />
                            <input type="hidden" name="referer" value={`${job.hostedUrl}`}/>
                            <input type="hidden" name="socialReferralKey"/>
                            <input type="hidden" name="source" />                          
                        </form>
                    </div>
                </div>
            </section>
        ]}
        </Layout>
    )
}
  
JobDetail.defaultProps = {
    id: null,
}
